import React, { useState } from "react";
import { Collapse, Button } from "react-bootstrap";

function Collapsed(props) {
  const [open, setOpen] = useState(false);
  const { children = null, name = "Подробнее", variant = "link" } = props;

  return (
    <React.Fragment>
      <Button variant={variant} onClick={() => setOpen(!open)}>
        {name}
      </Button>
      <Collapse in={open}>
        <div>{children}</div>
      </Collapse>
    </React.Fragment>
  );
}

export default Collapsed;
