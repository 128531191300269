import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
// import { ProtectedRoute } from './ProtectedRoute';
import Bus from "./Bus";
import Home from "./Home";
import Login from "./Login";
import Flash from "./Flash";

// import Container from 'react-bootstrap/Container';

// if (!process.env.REACT_APP_API_TOKEN) {
//   throw new Error('API token not found');
// }
//
// const apiUrl = process.env.REACT_APP_API_URL || '';
// const apiToken = process.env.REACT_APP_API_TOKEN;

window.flash = (message, type = "success") =>
  Bus.emit("flash", { message, type });

function App() {
  return (
    <BrowserRouter>
      <Flash />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/users/:userID" exact component={Home} />
        <Route path="/login" component={Login} />
        <Redirect exact from="/reload" to="/" />
        <Route path="*" component={() => "NOT FOUND"} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
