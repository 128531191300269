import React, { Component } from "react";
import { Button, Form, Table } from "react-bootstrap";

class AdditionalList extends Component {
  constructor(props) {
    super(props);

    this.handleAdd = this.handleAdd.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeValue = this.handleChangeValue.bind(this);
  }

  handleChangeName(event) {
    const target = event.target;
    let value = target.value;
    const index = parseInt(target.dataset.index, 10);

    const { values, onChange } = this.props;

    if (!values[index]) {
      return;
    }

    // if (value < 1) {
    //   this.handleRemove(index);
    //   return;
    // }

    values[index].name = value;
    onChange(values);
  }

  handleChangeValue(event) {
    const target = event.target;
    let value = parseInt(target.value, 10);
    const index = parseInt(target.dataset.index, 10);

    const { values, onChange } = this.props;

    if (!values[index]) {
      return;
    }

    // if (value < 1) {
    //   this.handleRemove(index);
    //   return;
    // }

    values[index].value = value;
    onChange(values);
  }

  handleAdd(event) {
    event.preventDefault();

    const { onChange } = this.props;
    let { values } = this.props;

    values.push({ name: "", value: 0 });
    onChange(values);
  }

  handleRemove(index) {
    const { onChange } = this.props;
    let { values } = this.props;

    if (!values[parseInt(index, 10)]) {
      return;
    }

    values.splice(parseInt(index, 10), 1);

    onChange(values);
  }

  render() {
    const { values, type = "install" } = this.props;

    let sum = 0;
    if (type === "install") {
      for (let i = 0; i < values.length; i++) {
        sum += parseInt(values[i].value, 10);
      }
    }

    return (
      <div>
        {values && values.length > 0 && (
          <Table striped bordered hover size="sm" responsive="sm">
            <thead>
              <tr>
                <th>Название</th>
                <th>{type === "install" ? "Сумма" : "Количество"}</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {values.map((item, index) => (
                <tr key={`additional-${index}`}>
                  <td>
                    <Form.Control
                      type={"text"}
                      value={item.name}
                      data-index={index}
                      onChange={this.handleChangeName}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="number"
                      min="0"
                      step="1"
                      value={item.value}
                      data-index={index}
                      onChange={this.handleChangeValue}
                    />
                  </td>
                  <td>
                    <button
                      type="button"
                      onClick={() => {
                        this.handleRemove(index);
                      }}
                    >
                      <i className="oi oi-circle-x" />
                    </button>
                  </td>
                </tr>
              ))}
              {type === "install" && (
                <tr>
                  <td className="text-right">Итого&nbsp;</td>
                  <td>
                    {new Intl.NumberFormat("ru-RU", {
                      style: "currency",
                      currency: "RUB",
                    }).format(sum)}
                  </td>
                  <td>&nbsp;</td>
                </tr>
              )}
            </tbody>
          </Table>
        )}

        <Button type={"button"} variant={"primary"} onClick={this.handleAdd}>
          Добавить
        </Button>
      </div>
    );
  }
}

export default AdditionalList;
