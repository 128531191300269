import React from "react";
import { Form } from "react-bootstrap";

export default function (props) {
  const { values, errors, onChange, onBlur } = props;
  return (
    <React.Fragment>
      <Form.Group controlId="formClientName">
        <Form.Label>Представитель заказчика ФИО</Form.Label>
        <Form.Control
          name="formClientName"
          type="text"
          placeholder="Иванов И.И."
          onChange={onChange}
          onBlur={onBlur}
          value={values.formClientName}
          required
          isInvalid={errors.formClientName}
        />
        {errors.formClientName && (
          <Form.Control.Feedback type="invalid">
            {errors.formClientName}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group controlId="formAddress">
        <Form.Label>Адрес объекта</Form.Label>
        <Form.Control
          name="formAddress"
          as="textarea"
          placeholder="Коломна, СНТ Ромашка, уч. 123"
          rows="4"
          onChange={onChange}
          onBlur={onBlur}
          value={values.formAddress}
          required
          isInvalid={errors.formAddress}
        />
        {errors.formAddress && (
          <Form.Control.Feedback type="invalid">
            {errors.formAddress}
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </React.Fragment>
  );
}
