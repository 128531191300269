import React, { Component } from "react";
import { Row, Col, Form, Button, Alert } from "react-bootstrap";
import { format, subMonths, addMonths } from "date-fns";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import InstalledList from "./InstalledList";
import ReturnedList from "./ReturnedList";
import ClientInfo from "./clientInfo";
import AdditionalList from "./AdditionalList";

class Finform extends Component {
  constructor(props) {
    super(props);

    const currentDate = format(new Date(), "yyyy-MM-dd");
    const minDate = format(subMonths(new Date(), 1), "yyyy-MM-dd");
    const maxDate = format(addMonths(new Date(), 1), "yyyy-MM-dd");

    this.FinformSchema = Yup.object().shape({
      formAmoNumber: Yup.number()
        .typeError("Необходимо указать номер")
        .moreThan(10000, "Слишком короткий номер")
        .required("Обязательное поле"),
      // .test(
      //   "amo",
      //   "Некорректный идентификатор сделки"
      //   // () => this.state.valid.formAmoNumber !== true
      // ),
      // .test("amo", "Некорректный идентификатор сделки", this.validateAmo),
      formClientName: Yup.string(),
      formAddress: Yup.string(),

      formInstallStartDate: Yup.date()
        .min(minDate, "Дата слишком далеко в прошлом")
        .max(maxDate, "Дата слишком далеко в будущем")
        .required("Обязательное поле"),
      formInstallEndDate: Yup.date()
        .min(minDate, "Дата слишком далеко в прошлом")
        .max(maxDate, "Дата слишком далеко в будущем")
        .required("Обязательное поле"),

      formPayType: Yup.string()
        .typeError("Необходимо выбрать тип оплаты")
        .min(2, "Необходимо выбрать тип оплаты")
        .required("Необходимо выбрать тип оплаты"),

      formFinRecv: Yup.number().typeError("Необходимо указать число"),
      // .moreThan(1, "Слишком маленькая сумма"),

      formAdditionalInstall: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().min(3, "Слишком короткий текст").required(),
          value: Yup.number().positive().integer().required(),
        })
      ),
      formAdditionalReturn: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().min(3, "Слишком короткий текст").required(),
          value: Yup.number().positive().integer().required(),
        })
      ),

      formInstallType: Yup.string().typeError("Необходимо выбрать"),
      formTransportBkm: Yup.number().typeError("Необходимо указать число"),
      formLeaseBkm: Yup.number().typeError("Необходимо указать число"),
      formTransportStaff: Yup.number().typeError("Необходимо указать число"),
      formTransportMetal: Yup.number().typeError("Необходимо указать число"),

      formInstalled: Yup.array()
        .of(Yup.object())
        .min(1, "Необходимо выбрать хотя бы одну сваю")
        .required("Обязательное поле"),
    });

    this.state = {
      sendError: false,
      sending: false,
      sendSuccess: false,
      isValid: false,

      valid: {
        formAmoNumber: null,
      },

      values: {
        formAmoNumber: "",
        formClientName: "",
        formAddress: "",
        formInstallStartDate: currentDate,
        formInstallEndDate: currentDate,

        formPayType: "",
        formFinRecv: 0,

        formAdditionalInstall: [],
        formAdditionalReturn: [],

        formInstallType: `hand`,
        formTransportBkm: 0,
        formLeaseBkm: 0,

        formTransportStaff: 0,
        formTransportMetal: 0,

        formInstalled: [],
        formReturned: [],
      },
      errors: {},
    };

    this.handleInstalledChange = this.handleInstalledChange.bind(this);
    this.handleReturnedChange = this.handleReturnedChange.bind(this);

    this.handleAdditionalInstallChange = this.handleAdditionalInstallChange.bind(
      this
    );
    this.handleAdditionalReturnChange = this.handleAdditionalReturnChange.bind(
      this
    );

    this.handleChangeAmo = this.handleChangeAmo.bind(this);
    this.handleBlurAmo = this.handleBlurAmo.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // fieldName, value
  validateFields() {
    const { values } = this.state;
    this.FinformSchema.validate(values, { abortEarly: false })
      .then((value) => {
        this.setState({ errors: {}, isValid: true });
        // console.log(value);
      })
      .catch((yupError) => {
        // console.dir(yupError);

        let errors = {};
        if (yupError.inner && yupError.inner.length > 0) {
          for (let err of yupError.inner) {
            errors[err.path] = err.message;
          }
        }

        this.setState({ errors: errors, isValid: false });
      });
  }

  handleInstalledChange(newList) {
    this.setState(
      (prevState) => ({
        values: {
          ...prevState.values,
          formInstalled: newList,
        },
      }),
      () => {
        this.validateFields();
      }
    );
  }

  handleAdditionalInstallChange(newList) {
    this.setState(
      (prevState) => ({
        values: {
          ...prevState.values,
          formAdditionalInstall: newList,
        },
      }),
      () => {
        this.validateFields();
      }
    );
  }

  handleAdditionalReturnChange(newList) {
    this.setState(
      (prevState) => ({
        values: {
          ...prevState.values,
          formAdditionalReturn: newList,
        },
      }),
      () => {
        this.validateFields();
      }
    );
  }

  handleReturnedChange(newList) {
    this.setState(
      (prevState) => ({
        values: {
          ...prevState.values,
          formReturned: newList,
        },
      }),
      () => {
        this.validateFields();
      }
    );
  }

  handleChangeAmo(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState(
      (prevState) => ({
        valid: {
          ...prevState.valid,
          formAmoNumber: null,
        },
        values: {
          ...prevState.values,
          [name]: value,
        },
      }),
      () => {
        this.validateFields();
      }
    );
  }

  handleBlurAmo(event) {
    const target = event.target;
    const value = target.value;

    // const { valid } = this.state;
    // const { formAmoNumber } = valid;

    // if (formAmoNumber !== null) {
    //   this.handleBlur(event);
    //   return;
    // }

    // this.handleBlur(event);

    this.setState(
      (prevState) => ({
        valid: {
          ...prevState.valid,
          formAmoNumber: null,
        },
      }),
      () => {
        this.validateAmo(value);
      }
    );
  }

  handleChange(event) {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    if (name === "formInstallType") {
      // console.log(value);
      // console.log(target.checked);
      if (target.checked === true) {
        value = "mech";
      } else {
        value = "hand";
      }
    }

    // console.dir(name);
    // console.log(value);

    this.setState(
      (prevState) => ({
        values: {
          ...prevState.values,
          [name]: value,
        },
      }),
      () => {
        this.validateFields();
      }
    );
  }

  handleBlur(event) {
    this.validateFields();
  }

  validateAmo(id = null) {
    // console.log("validateAMO", id);
    if (id === null) {
      return false;
    }

    if (!process.env.REACT_APP_API_URL) {
      throw new Error("API URL not found");
    }

    const apiUrl = process.env.REACT_APP_API_URL || "";
    fetch(`${apiUrl}/amo/${id}`, {
      credentials: "include",
      cache: "no-cache",
    })
      .then((response) => {
        // console.dir(response);
        if (this.mounted === false) {
          return;
        }

        if (response.status === 401 || response.status === 404) {
          this.setState((prevState) => ({
            errors: {
              ...prevState.errors,
              formAmoNumber:
                "Некорректный номер заявки. Обратитесть за помощью к администратору",
            },
          }));
        }

        response.json().then((data) => {
          // console.dir(data);
          if (data.found && data.found === true) {
            // console.log("found");
            this.setState((prevState) => ({
              valid: {
                ...prevState.valid,
                formAmoNumber: true,
              },
              values: {
                ...prevState.values,
                formClientName:
                  prevState.values.formClientName === ""
                    ? data.name
                    : prevState.values.formClientName,
                formAddress:
                  prevState.values.formAddress === ""
                    ? data.address
                    : prevState.values.formAddress,
              },
            }));

            return;
          }

          this.setState((prevState) => ({
            errors: {
              ...prevState.errors,
              formAmoNumber:
                "Некорректный номер заявки. Обратитесть за помощью к администратору",
            },
          }));
        });
      })
      .catch(() => {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            formAmoNumber:
              "Некорректный номер заявки. Обратитесть за помощью к администратору",
          },
        }));
      });
  }

  postData() {
    if (!process.env.REACT_APP_API_URL) {
      throw new Error("API URL not found");
    }

    const { values } = this.state;
    const calc = this.calculate();

    const apiUrl = process.env.REACT_APP_API_URL || "";

    // const data = ;
    // console.dir(data);
    // return;

    fetch(`${apiUrl}/report`, {
      method: "POST",
      credentials: "include",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        // "Content-Type": "application/x-www-form-urlencoded",
      },
      body: JSON.stringify(Object.assign(calc, values)),
    })
      .then((response) => {
        console.dir(response);
        if (response.status !== 200) {
          this.setState({
            sending: false,
            sendError: true,
          });
        } else {
          this.setState({
            sending: false,
            sendSuccess: true,
          });
        }
      })
      .catch(() => {
        this.setState({
          sending: false,
          sendError: true,
        });
      });
  }

  handleSubmit(event) {
    event.preventDefault();

    const { values, sending } = this.state;

    if (sending === true) {
      return;
    }

    this.setState({ sending: true }, () => {
      this.FinformSchema.isValid(values).then((valid) => {
        if (valid === true) {
          this.setState({ sending: true });
          this.postData();
        } else {
          this.setState({ sending: false });
        }
      });
    });
  }

  calculate() {
    const { values } = this.state;
    const formFinRecv = parseInt(values.formFinRecv, 10) || 0;

    const result = {};

    result.installSum = 0;
    for (let i = 0; i < values.formInstalled.length; i++) {
      result.installSum +=
        parseInt(values.formInstalled[i].count, 10) *
        parseInt(values.formInstalled[i].price, 10);
    }

    result.additionalSum = 0;
    for (let i = 0; i < values.formAdditionalInstall.length; i++) {
      result.additionalSum += parseInt(
        values.formAdditionalInstall[i].value,
        10
      );
    }

    result.installAllSum = result.installSum + result.additionalSum;

    result.transportSum =
      parseInt(values.formTransportBkm, 10) +
      parseInt(values.formTransportMetal, 10) +
      parseInt(values.formTransportStaff, 10) +
      parseInt(values.formLeaseBkm, 10);

    result.returnSum = formFinRecv - result.installAllSum - result.transportSum;

    return result;
  }

  render() {
    const { prices, user } = this.props;
    const {
      sendSuccess,
      sendError,
      values,
      errors,
      valid,
      isValid,
      sending,
    } = this.state;

    if (sendSuccess === true) {
      return (
        <Alert className="mt-4" variant="success">
          Данные отправлены!
          <br />
          <Link to="/reload">Вернуться</Link>
        </Alert>
      );
    }

    const minDate = format(subMonths(new Date(), 1), "yyyy-MM-dd");
    const maxDate = format(addMonths(new Date(), 1), "yyyy-MM-dd");

    const { installAllSum, transportSum, returnSum } = this.calculate();

    // console.log(prices);

    return (
      <Form onSubmit={this.handleSubmit}>
        <h3 className={"my-4"}>
          Финансовый отчет от {format(new Date(), "dd.MM.yyyy")}
        </h3>
        <h5>Бригадир:&nbsp;{user.name}</h5>
        {sendError && (
          <Alert variant="danger">
            При отправке формы произошла ошибка. Попробуйте еще раз или
            свяжитесь с администратором!
          </Alert>
        )}

        <Form.Group controlId="formAmoNumber">
          <Form.Label>Заявка №</Form.Label>
          <Form.Control
            name={"formAmoNumber"}
            type={"number"}
            placeholder={"Номер заявки"}
            onChange={this.handleChangeAmo}
            onBlur={this.handleBlurAmo}
            isValid={valid.formAmoNumber}
            required
            isInvalid={errors.formAmoNumber}
          />
          {errors.formAmoNumber && (
            <Form.Control.Feedback type="invalid">
              {errors.formAmoNumber}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <hr />

        <ClientInfo
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          values={values}
          errors={errors}
        />

        <Row>
          <Form.Group as={Col} controlId="formInstallStartDate">
            <Form.Label>Дата начала СМР</Form.Label>
            <Form.Control
              name="formInstallStartDate"
              type="date"
              min={minDate}
              max={maxDate}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              value={values.formInstallStartDate}
              required
              isInvalid={errors.formInstallStartDate}
            />
            {errors.formInstallStartDate && (
              <Form.Control.Feedback type="invalid">
                {errors.formInstallStartDate}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group as={Col} controlId="formInstallEndDate">
            <Form.Label>Дата окончания СМР</Form.Label>
            <Form.Control
              name="formInstallEndDate"
              type="date"
              min={minDate}
              max={maxDate}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              value={values.formInstallEndDate}
              required
              isInvalid={errors.formInstallEndDate}
            />
            {errors.formInstallEndDate && (
              <Form.Control.Feedback type="invalid">
                {errors.formInstallEndDate}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Row>

        <hr />

        <fieldset className="border p-2 mb-3">
          <legend className="w-auto">Оплата</legend>

          <Form.Group controlId="formPayType">
            {/*<Form.Label></Form.Label>*/}

            <Form.Check
              name={"formPayType"}
              inline
              label="Наличные"
              value={"cash"}
              type={"radio"}
              checked={values.formPayType === "cash"}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
            />

            <Form.Check
              name={"formPayType"}
              inline
              label="Терминал"
              value={"terminal"}
              type={"radio"}
              checked={values.formPayType === "terminal"}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
            />

            <Form.Check
              name={"formPayType"}
              inline
              label="Безнал"
              value={"bank"}
              type={"radio"}
              checked={values.formPayType === "bank"}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
            />

            {errors.formPayType && (
              <div style={{ display: "block" }} className="invalid-feedback">
                {errors.formPayType}
              </div>
            )}
          </Form.Group>

          <Form.Group controlId="formFinRecv">
            <Form.Label>Оплаченная сумма, &#8381;</Form.Label>

            <Form.Control
              name={"formFinRecv"}
              type={"number"}
              min={"1"}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              value={values.formFinRecv}
              isInvalid={errors.formFinRecv}
            />

            {errors.formFinRecv && (
              <Form.Control.Feedback type="invalid">
                {errors.formFinRecv}
              </Form.Control.Feedback>
            )}
            <Form.Text className="text-muted">
              Получено на руки от заказчика или его представителя
            </Form.Text>
          </Form.Group>
        </fieldset>

        <h4>Строительно-монтажные работы</h4>

        <fieldset className="border p-2 mb-3">
          <legend className="w-auto">Основные работы</legend>
          <Form.Group controlId="formInstallType">
            <Form.Label>Тип монтажа</Form.Label>

            <Form.Switch
              name={"formInstallType"}
              id={"formInstallType"}
              label="Монтаж с БКМ"
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              checked={values.formInstallType === "mech"}
            />
            {/*{values.formInstallType}*/}

            <Form.Text className="text-muted">
              {values.formInstallType === "hand" ? (
                <React.Fragment>Выбран ручной монтаж</React.Fragment>
              ) : (
                <React.Fragment>Выбран монтаж с БКМ</React.Fragment>
              )}
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="formInstalled">
            <InstalledList
              name="formInstalled"
              installType={values.formInstallType}
              onChange={this.handleInstalledChange}
              prices={prices}
              values={values.formInstalled}
            />

            {errors.formInstalled && (
              <div style={{ display: "block" }} className="invalid-feedback">
                {errors.formInstalled}
              </div>
            )}
          </Form.Group>

          <hr />

          <h5>Дополнительные работы</h5>

          <Form.Group controlId="formAdditionalInstall">
            <AdditionalList
              type={"install"}
              values={values.formAdditionalInstall}
              onChange={this.handleAdditionalInstallChange}
            />
            {errors.formAdditionalInstall && (
              <div style={{ display: "block" }} className="invalid-feedback">
                {errors.formAdditionalInstall}
              </div>
            )}

            {/*<div>*/}
            {/*  Итого:&nbsp;*/}
            {/*  {new Intl.NumberFormat("ru-RU", {*/}
            {/*    style: "currency",*/}
            {/*    currency: "RUB"*/}
            {/*  }).format(additionalSum)}*/}
            {/*</div>*/}
          </Form.Group>

          <h3>
            Итого:&nbsp;
            <span>
              {new Intl.NumberFormat("ru-RU", {
                style: "currency",
                currency: "RUB",
              }).format(installAllSum)}
            </span>
          </h3>
        </fieldset>

        <fieldset className="border p-2 mb-3">
          <legend className="w-auto">Доставка</legend>

          {values.formInstallType && values.formInstallType === "mech" && (
            <Row>
              <Form.Group as={Col} controlId="formTransportBkm">
                <Form.Label>Доставка БКМ</Form.Label>
                <Form.Control
                  name="formTransportBkm"
                  type="number"
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  value={values.formTransportBkm}
                  isInvalid={errors.formTransportBkm}
                  required
                />
                {errors.formTransportBkm && (
                  <Form.Control.Feedback type="invalid">
                    {errors.formTransportBkm}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group as={Col} controlId="formLeaseBkm">
                <Form.Label>Аренда БКМ</Form.Label>
                <Form.Control
                  name="formLeaseBkm"
                  type="number"
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  value={values.formLeaseBkm}
                  isInvalid={errors.formLeaseBkm}
                  required
                />
                {errors.formLeaseBkm && (
                  <Form.Control.Feedback type="invalid">
                    {errors.formLeaseBkm}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Row>
          )}

          <Row>
            <Form.Group as={Col} controlId="formTransportStaff">
              <Form.Label>Доставка бригады</Form.Label>
              <Form.Control
                name="formTransportStaff"
                type="number"
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                value={values.formTransportStaff}
                isInvalid={errors.formTransportStaff}
                required
              />
              {errors.formTransportStaff && (
                <Form.Control.Feedback type="invalid">
                  {errors.formTransportStaff}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group as={Col} controlId="formTransportMetal">
              <Form.Label>Доставка металла</Form.Label>
              <Form.Control
                name="formTransportMetal"
                type="number"
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                value={values.formTransportMetal}
                isInvalid={errors.formTransportMetal}
                required
              />
              {errors.formTransportMetal && (
                <Form.Control.Feedback type="invalid">
                  {errors.formTransportMetal}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Row>

          <h3>
            Итого:&nbsp;
            <span>
              {new Intl.NumberFormat("ru-RU", {
                style: "currency",
                currency: "RUB",
              }).format(transportSum)}
            </span>
          </h3>
        </fieldset>

        <h2>
          Сумма к сдаче:&nbsp;
          <span>
            {new Intl.NumberFormat("ru-RU", {
              style: "currency",
              currency: "RUB",
            }).format(returnSum)}
          </span>
        </h2>

        <fieldset className="border p-2 mb-3">
          <legend className="w-auto">Возврат на склад</legend>
          <Form.Group controlId="formReturned">
            <Form.Label>Материал</Form.Label>

            <ReturnedList
              name="formReturned"
              onChange={this.handleReturnedChange}
              prices={prices}
              values={values.formReturned}
            />
          </Form.Group>

          <hr />

          <h5>Дополнительно</h5>

          <Form.Group controlId="formAdditionalReturn">
            <AdditionalList
              type={"return"}
              values={values.formAdditionalReturn}
              onChange={this.handleAdditionalReturnChange}
            />
            {errors.formAdditionalReturn && (
              <div style={{ display: "block" }} className="invalid-feedback">
                {errors.formAdditionalReturn}
              </div>
            )}
          </Form.Group>
        </fieldset>

        <Button
          variant="primary"
          size="lg"
          block
          type="submit"
          disabled={!isValid || sending}
        >
          {sending ? "Отправка" : "Отправить"}
        </Button>
      </Form>
    );
  }
}

export default Finform;
