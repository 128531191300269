import React from "react";
import { Spinner, Form, Row, Col, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCheck, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import { NavLink } from "react-router-dom";

const userSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Слишком короткое имя")
    .max(100, "Слишком длинное имя")
    .required("Поле является обязательным"),
  login: Yup.string()
    .min(2, "Слишком короткий логин")
    .max(100, "Слишком длинный логин")
    .required("Поле является обязательным"),
  phone: Yup.string()
    .min(6, "Слишком короткий номер")
    .max(20, "Слишком длинный номер")
    .required("Поле является обязательным"),
});

const passwordSchema = Yup.object().shape({
  password: Yup.string()
    .min(5, "Слишком короткий пароль")
    .max(100, "Слишком длинный пароль")
    .required("Поле является обязательным"),
});

export default class UserEdit extends React.Component {
  constructor(props) {
    super(props);

    this.mounted = false;

    this.state = {
      user: null,
      isLoadError: false,
      isLoading: true,
      isSaved: false,
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  updateUser(values = null) {
    if (values === null) {
      return;
    }

    if (!process.env.REACT_APP_API_URL) {
      throw new Error("API URL not found");
    }

    const { userID } = this.props;

    const apiUrl = process.env.REACT_APP_API_URL || "";

    fetch(`${apiUrl}/users/${userID}`, {
      method: "POST",
      credentials: "include",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        // "Content-Type": "application/x-www-form-urlencoded",
      },
      body: JSON.stringify(values),
    }).then((response) => {
      if (this.mounted === false) {
        return;
      }

      this.setState({
        isSaved: true,
      });

      // this.loadData();
    });
  }

  updatePassword(values) {
    if (values === null) {
      return;
    }

    if (!process.env.REACT_APP_API_URL) {
      throw new Error("API URL not found");
    }

    const { userID } = this.props;

    const apiUrl = process.env.REACT_APP_API_URL || "";

    fetch(`${apiUrl}/users/${userID}/password`, {
      method: "POST",
      credentials: "include",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        // "Content-Type": "application/x-www-form-urlencoded",
      },
      body: JSON.stringify(values),
    }).then((response) => {
      if (this.mounted === false) {
        return;
      }

      this.setState({
        isSaved: true,
      });
    });
  }

  loadData() {
    if (!process.env.REACT_APP_API_URL) {
      throw new Error("API URL not found");
    }

    const { userID } = this.props;

    const apiUrl = process.env.REACT_APP_API_URL || "";
    fetch(`${apiUrl}/users/${userID}`, {
      credentials: "include",
      cache: "no-cache",
    })
      .then((response) => {
        if (this.mounted === false) {
          return;
        }

        if (response.status !== 200) {
          this.setState({ isLoadError: true });
          return;
        }

        response.json().then((data) => {
          // console.dir(data);
          this.setState({
            user: data,
            isLoading: false,
          });
        });
      })
      .catch(() => {
        // TODO: Show error to user
      });
  }

  render() {
    const { isLoading, user, isLoadError, isSaved } = this.state;

    if (isSaved === true) {
      return <Redirect to="/" />;
    }

    if (isLoadError === true) {
      return (
        <div>
          <b>Ошибка загрузки...</b>
          <br />
          Попробуйте перезагрузить страницу.
        </div>
      );
    }

    if (isLoading) {
      return (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      );
    }

    return (
      <React.Fragment>
        <div className={"my-2"}>
          <NavLink to={"/"}>Вернуться</NavLink>
        </div>

        <h3 className={"mt-2"}>Редактирование: {user.name}</h3>
        <Formik
          initialValues={{
            name: user.name,
            login: user.login,
            phone: user.phone,
          }}
          validationSchema={userSchema}
          onSubmit={(values) => {
            this.updateUser(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <fieldset>
                <Row>
                  <Col>
                    <Form.Group controlId="name">
                      <Form.Label>Имя</Form.Label>
                      <Form.Control
                        name={"name"}
                        type={"text"}
                        placeholder={"Введите имя бригадира"}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        isInvalid={!!errors.name}
                      />
                      {errors.name && touched.name ? (
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      ) : null}
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group controlId="login">
                      <Form.Label>Логин</Form.Label>
                      <Form.Control
                        name={"login"}
                        type={"text"}
                        placeholder={"Введите логин"}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.login}
                        isInvalid={!!errors.login}
                      />
                      {errors.login && touched.login ? (
                        <Form.Control.Feedback type="invalid">
                          {errors.login}
                        </Form.Control.Feedback>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="phone">
                      <Form.Label>Телефон</Form.Label>
                      <Form.Control
                        name={"phone"}
                        type={"text"}
                        placeholder={"Введите телефон"}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                        isInvalid={!!errors.phone}
                      />
                      {errors.phone && touched.phone ? (
                        <Form.Control.Feedback type="invalid">
                          {errors.phone}
                        </Form.Control.Feedback>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
                <Button disabled={isSubmitting} variant="primary" type="submit">
                  Сохранить
                </Button>
              </fieldset>
            </Form>
          )}
        </Formik>

        <hr className={"my-4"} />

        <h3>Изменение пароля</h3>
        <Formik
          initialValues={{
            password: "",
          }}
          validationSchema={passwordSchema}
          onSubmit={(values) => {
            this.updatePassword(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <fieldset>
                <Row>
                  <Col>
                    <Form.Group controlId="password">
                      <Form.Label>Пароль</Form.Label>
                      <Form.Control
                        type={"password"}
                        placeholder={"Введите пароль"}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        isInvalid={!!errors.password}
                      />
                      {errors.password && touched.password ? (
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
                <Button disabled={isSubmitting} variant="primary" type="submit">
                  Сохранить
                </Button>
              </fieldset>
            </Form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}
