class Auth {
  constructor() {
    this.authenticated = false;
  }

  login(params) {
    const { login, password, cb } = params;

    if (!process.env.REACT_APP_API_URL) {
      throw new Error('API URL not found');
    }

    const apiUrl = process.env.REACT_APP_API_URL || '';

    const formData = new FormData();
    formData.append('login', login);
    formData.append('password', password);

    fetch(`${apiUrl}/login`, {
      method: 'POST',
      credentials: 'include',
      cache: 'no-cache',
      body: formData,
    })
      .then((response) => {
        if (response.status === 404 || response.status === 401) {
          this.authenticated = false;
          cb();
        } else {
          response.json()
            .then((data) => {
              this.authenticated = data.success === true;
              cb();
            });
        }
      })
      .catch(() => {
        this.authenticated = false;
        cb();
        // TODO: Показывать ошибку пользователю
      });
  }

  logout(cb) {
    if (!process.env.REACT_APP_API_URL) {
      throw new Error('API URL not found');
    }

    const apiUrl = process.env.REACT_APP_API_URL || '';

    fetch(`${apiUrl}/logout`, {
      credentials: 'include',
    })
      .then((response) => {
        // TODO: Сделать обработку ошибок
        this.authenticated = false;
        cb();
      })
      .catch(() => {
        this.authenticated = false;
        cb();
        // TODO: Показывать ошибку пользователю
      });
  }

  isAuthenticated() {
    return this.authenticated;
  }
}

export default new Auth();
