import React from 'react';
import { Navbar } from 'react-bootstrap';

export default function Footer() {
  return (
    <Navbar bg="light" variant="light" fixed="bottom">
      Helix-Pro &copy;
      {' '}
      {new Date().getFullYear()}
    </Navbar>
  );
}
