import React from "react";
import { Spinner, Table, Form, Row, Col, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faTrash,
  faUserEdit,
  faToggleOn,
  faToggleOff,
} from "@fortawesome/free-solid-svg-icons";
import { format, parseJSON } from "date-fns";
import { Link } from "react-router-dom";

const userSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Слишком короткое имя")
    .max(100, "Слишком длинное имя")
    .required("Поле является обязательным"),
  login: Yup.string()
    .min(2, "Слишком короткий логин")
    .max(100, "Слишком длинный логин")
    .required("Поле является обязательным"),
  phone: Yup.string()
    .min(6, "Слишком короткий номер")
    .max(20, "Слишком длинный номер")
    .required("Поле является обязательным"),
  password: Yup.string()
    .min(5, "Слишком короткий пароль")
    .max(100, "Слишком длинный пароль")
    .required("Поле является обязательным"),
});

export default class Users extends React.Component {
  constructor(props) {
    super(props);

    this.mounted = false;

    this.state = {
      tgusers: null,
      isLoadError: false,
      isLoading: true,
    };

    // this.handleUpdatePrice = this.handleUpdatePrice.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  removeUser(id = null) {
    if (id === null || id < 0) {
      return;
    }

    if (!process.env.REACT_APP_API_URL) {
      throw new Error("API URL not found");
    }

    const apiUrl = process.env.REACT_APP_API_URL || "";

    fetch(`${apiUrl}/users/${id}`, {
      method: "DELETE",
      credentials: "include",
      cache: "no-cache",
    }).then((response) => {
      if (this.mounted === false) {
        return;
      }

      this.loadData();
    });
  }

  changeUserStatus(id = null, active = true) {
    if (id === null || id < 0) {
      return;
    }

    if (!process.env.REACT_APP_API_URL) {
      throw new Error("API URL not found");
    }

    const apiUrl = process.env.REACT_APP_API_URL || "";

    fetch(`${apiUrl}/users/${id}/status`, {
      method: "POST",
      credentials: "include",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        // "Content-Type": "application/x-www-form-urlencoded",
      },
      body: JSON.stringify({ status: active }),
    }).then((response) => {
      if (this.mounted === false) {
        return;
      }

      this.loadData();
    });
  }

  createUser(values = null, cb = null) {
    if (values === null) {
      return;
    }

    if (!process.env.REACT_APP_API_URL) {
      throw new Error("API URL not found");
    }

    const apiUrl = process.env.REACT_APP_API_URL || "";

    fetch(`${apiUrl}/users`, {
      method: "PUT",
      credentials: "include",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        // "Content-Type": "application/x-www-form-urlencoded",
      },
      body: JSON.stringify(values),
    }).then((response) => {
      if (this.mounted === false) {
        return;
      }

      if (cb) {
        cb();
      }

      this.loadData();
    });
  }

  loadData() {
    if (!process.env.REACT_APP_API_URL) {
      throw new Error("API URL not found");
    }

    const apiUrl = process.env.REACT_APP_API_URL || "";
    fetch(`${apiUrl}/users`, {
      credentials: "include",
      cache: "no-cache",
    })
      .then((response) => {
        if (this.mounted === false) {
          return;
        }

        if (response.status !== 200) {
          this.setState({ isLoadError: true });
          return;
        }

        response.json().then((data) => {
          // console.dir(data);
          this.setState({
            users: data,
            isLoading: false,
          });
        });
      })
      .catch(() => {
        // TODO: Show error to user
      });
  }

  render() {
    const { isLoading, users, isLoadError } = this.state;

    if (isLoadError === true) {
      return (
        <div>
          <b>Ошибка загрузки...</b>
          <br />
          Попробуйте перезагрузить страницу.
        </div>
      );
    }

    if (isLoading) {
      return (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      );
    }

    return (
      <React.Fragment>
        <h3 className={"mt-2"}>Бригадиры</h3>
        <Formik
          initialValues={{ name: "", login: "", phone: "", password: "" }}
          validationSchema={userSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            this.createUser(values, () => {
              setSubmitting(false);
              resetForm();
            });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <fieldset>
                <Row>
                  <Col>
                    <Form.Group controlId="name">
                      <Form.Label>Имя</Form.Label>
                      <Form.Control
                        name={"name"}
                        type={"text"}
                        placeholder={"Введите имя бригадира"}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        isInvalid={!!errors.name}
                      />
                      {errors.name && touched.name ? (
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      ) : null}
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group controlId="login">
                      <Form.Label>Логин</Form.Label>
                      <Form.Control
                        name={"login"}
                        type={"text"}
                        placeholder={"Введите логин"}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.login}
                        isInvalid={!!errors.login}
                      />
                      {errors.login && touched.login ? (
                        <Form.Control.Feedback type="invalid">
                          {errors.login}
                        </Form.Control.Feedback>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="phone">
                      <Form.Label>Телефон</Form.Label>
                      <Form.Control
                        name={"phone"}
                        type={"text"}
                        placeholder={"Введите телефон"}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                        isInvalid={!!errors.phone}
                      />
                      {errors.phone && touched.phone ? (
                        <Form.Control.Feedback type="invalid">
                          {errors.phone}
                        </Form.Control.Feedback>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="password">
                      <Form.Label>Пароль</Form.Label>
                      <Form.Control
                        name={"password"}
                        type={"password"}
                        placeholder={"Введите пароль"}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        isInvalid={!!errors.password}
                      />
                      {errors.password && touched.password ? (
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
                <Button disabled={isSubmitting} variant="primary" type="submit">
                  Добавить
                </Button>
              </fieldset>
            </Form>
          )}
        </Formik>

        <hr />

        {users && users.length > 0 && (
          <Table striped bordered hover size="sm" responsive="sm">
            <thead>
              <tr>
                <th className={"text-center"}>ID</th>
                <th className={"text-center"}>Активен</th>
                <th className={"text-center"}>Админ</th>
                <th className={"text-center"}>Имя</th>
                <th className={"text-center"}>Логин</th>
                <th className={"text-center"}>Телефон</th>
                <th className={"text-center"}>Дата создания</th>
                <th className={"text-center"}>Последнее изменение</th>
                <th className={"text-center"}>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {users.map((item) => (
                <tr key={item.id}>
                  <td className={"text-center"}>{item.id}</td>
                  <td className={"text-center"}>
                    {item.active === true ? (
                      <FontAwesomeIcon icon={faCheck} />
                    ) : null}
                  </td>
                  <td className={"text-center"}>
                    {item.admin === true ? (
                      <FontAwesomeIcon icon={faCheck} />
                    ) : null}
                  </td>
                  <td className={"text-center"}>{item.name}</td>
                  <td className={"text-center"}>{item.login}</td>
                  <td className={"text-center"}>
                    <a href={`tel:${item.phone}`}>{item.phone}</a>
                  </td>
                  <td className={"text-center"}>
                    {item.createdAt && item.createdAt.trim() !== ""
                      ? format(parseJSON(item.createdAt), "dd.MM.yyyy H:mm:ss")
                      : null}
                  </td>
                  <td className={"text-center"}>
                    {item.updatedAt && item.updatedAt.trim() !== ""
                      ? format(parseJSON(item.updatedAt), "dd.MM.yyyy H:mm:ss")
                      : null}
                  </td>
                  <td className={"text-center"}>
                    <div className="btn-group">
                      <Button
                        variant={
                          item.active === true ? "outline-success" : "success"
                        }
                        onClick={() => {
                          if (window.confirm("Вы уверены?"))
                            this.changeUserStatus(
                              item.id,
                              item.active !== true
                            );
                        }}
                      >
                        {item.active === true ? "ВЫКЛ" : "ВКЛ"}
                      </Button>
                      <Button
                        as={Link}
                        variant="secondary"
                        to={`/users/${item.id}`}
                      >
                        <FontAwesomeIcon icon={faUserEdit} />
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => {
                          if (window.confirm("Вы уверены?"))
                            this.removeUser(item.id);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </React.Fragment>
    );
  }
}
