import React, { Component } from "react";
import { format } from "date-fns";
import { Button } from "react-bootstrap";

class UpdatePrice extends Component {
  constructor(props) {
    super(props);

    this.mounted = false;

    this.timer = null;

    this.state = {
      isUpdating: false,
      updated: null,
      isLoadError: false,
      jobID: null,
    };

    this.checkUpdate = this.checkUpdate.bind(this);
    this.updatePrice = this.updatePrice.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  checkUpdate() {
    const { jobID, isUpdating } = this.state;

    if (jobID === null || isUpdating === false || this.mounted === false) {
      clearInterval(this.timer);
      return;
    }

    if (!process.env.REACT_APP_API_URL) {
      throw new Error("API URL not found");
    }

    const apiUrl = process.env.REACT_APP_API_URL || "";
    fetch(`${apiUrl}/sync/${jobID}`, {
      credentials: "include",
      cache: "no-cache",
    })
      .then((response) => {
        if (this.mounted === false) {
          return;
        }

        if (response.status !== 200) {
          // alert(response.status);
          clearInterval(this.timer);
          this.setState({
            isLoadError: true,
            isUpdating: false,
            jobID: null,
            updated: null,
          });
          return;
        }

        response.json().then((data) => {
          // console.dir(data);
          if (data.success && data.success === true && data.status) {
            if (data.status === "done") {
              clearInterval(this.timer);

              if (this.props.handleUpdatePrice) {
                this.props.handleUpdatePrice();
              }

              this.setState({
                isUpdating: false,
                jobID: null,
                // updated: format(utcToZonedTime(new Date(), 'Europe/Moscow'), 'HH:mm:ss'),
                updated: format(new Date(), "HH:mm:ss"),
              });
            }
          } else {
            clearInterval(this.timer);

            this.setState({
              isLoadError: true,
              isUpdating: false,
              jobID: null,
              updated: null,
            });
          }
        });
      })
      .catch(() => {
        // TODO: Show error to user
      });
  }

  updatePrice() {
    const { isUpdating, jobID } = this.state;
    if (isUpdating === true || jobID !== null || this.mounted === false) {
      return;
    }

    if (!process.env.REACT_APP_API_URL) {
      throw new Error("API URL not found");
    }

    const apiUrl = process.env.REACT_APP_API_URL || "";
    fetch(`${apiUrl}/sync`, {
      method: "POST",
      credentials: "include",
      cache: "no-cache",
    })
      .then((response) => {
        if (this.mounted === false) {
          return;
        }

        if (response.status !== 200) {
          // alert(response.status);
          this.setState({
            isLoadError: true,
            isUpdating: false,
            jobID: null,
            updated: null,
          });
          return;
        }

        response.json().then((data) => {
          // console.dir(data);
          if (data.success && data.success === true) {
            if (data.id) {
              this.timer = setInterval(this.checkUpdate.bind(this), 2000);
              this.setState({
                isLoadError: false,
                isUpdating: true,
                jobID: parseInt(data.id, 10),
                updated: null,
              });
            }
          }
        });
      })
      .catch(() => {
        // TODO: Show error to user
      });
  }

  render() {
    const { isUpdating, isLoadError, updated } = this.state;

    if (isUpdating === true) {
      return (
        <span>
          <i className="oi oi-loop-circular" /> обновление...
        </span>
      );
    }

    let status = "";
    if (isLoadError === false && updated !== null) {
      status = `Обновлено: ${updated}`;
    }

    if (isLoadError === true) {
      status = "Ошибка обновления данных";
    }
    return (
      <span>
        <Button variant="info" type="button" onClick={this.updatePrice}>
          <i className="oi oi-loop-circular" />
          &nbsp; Обновить
        </Button>
        {status && <div>{status}</div>}
      </span>
    );
  }
}

export default UpdatePrice;
