import React from 'react';
import { Container, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';

import auth from './auth';

export default function Login(props) {
  const LoginSchema = Yup.object()
    .shape({
      login: Yup.string()
        .min(2, 'Слишком короткий логин')
        .required('Обязательное поле'),
      password: Yup.string()
        .min(2, 'Слишком короткий пароль')
        .required('Обязательное поле'),
    });

  return (
    <Container>
      <fieldset className="border p-2 mb-3">
        <Formik
          initialValues={{
            login: '',
            password: '',
          }}
          validationSchema={LoginSchema}
          onSubmit={(values, { setSubmitting }) => {
            auth.login(
              {
                login: values.login,
                password: values.password,
                cb: () => {
                  setSubmitting(false);

                  if (auth.authenticated === true) {
                    props.history.push('/');
                  } else {
                    window.flash('Ошибка входа', 'danger');
                  }
                },
              },
            );
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="login">
                <Form.Label>Имя пользователя</Form.Label>
                <Form.Control
                  type="text"
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.login}
                />
              </Form.Group>
              {errors.login && touched.login && errors.login}

              <Form.Group controlId="password">
                <Form.Label>Пароль</Form.Label>
                <Form.Control
                  type="password"
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />
              </Form.Group>
              {errors.password && touched.password && errors.password}

              {/* <Form.Group controlId="remember"> */}
              {/*  <Form.Control */}
              {/*      type="checkbox" */}
              {/*      disabled */}
              {/*  /> Запомнить меня */}
              {/* </Form.Group> */}

              <button type="submit" disabled={isSubmitting}>
                Войти
              </button>
            </Form>
          )}
        </Formik>
      </fieldset>
    </Container>
  );
}
