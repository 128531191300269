import React from "react";
import { Spinner, Table, Form, Row, Col, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";

const tguserSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Слишком короткое имя")
    .max(100, "Слишком длинное имя")
    .required("Поле является обязательным"),
  userId: Yup.number().required("Поле является обязательным"),
});

export default class Tgusers extends React.Component {
  constructor(props) {
    super(props);

    this.mounted = false;

    this.state = {
      tgusers: null,
      isLoadError: false,
      isLoading: true,
    };

    // this.handleUpdatePrice = this.handleUpdatePrice.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  removeTguser(id = null) {
    if (id === null || id < 0) {
      return;
    }

    if (!process.env.REACT_APP_API_URL) {
      throw new Error("API URL not found");
    }

    const apiUrl = process.env.REACT_APP_API_URL || "";

    fetch(`${apiUrl}/telegram/${id}`, {
      method: "DELETE",
      credentials: "include",
      cache: "no-cache",
    }).then((response) => {
      if (this.mounted === false) {
        return;
      }

      this.loadData();
    });
  }

  createTguser(values = null, cb = null) {
    if (values === null) {
      return;
    }

    if (!process.env.REACT_APP_API_URL) {
      throw new Error("API URL not found");
    }

    const apiUrl = process.env.REACT_APP_API_URL || "";

    fetch(`${apiUrl}/telegram`, {
      method: "PUT",
      credentials: "include",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        // "Content-Type": "application/x-www-form-urlencoded",
      },
      body: JSON.stringify(values),
    }).then((response) => {
      if (this.mounted === false) {
        return;
      }

      if (cb) {
        cb();
      }

      this.loadData();
    });
  }

  loadData() {
    if (!process.env.REACT_APP_API_URL) {
      throw new Error("API URL not found");
    }

    const apiUrl = process.env.REACT_APP_API_URL || "";
    fetch(`${apiUrl}/telegram`, {
      credentials: "include",
      cache: "no-cache",
    })
      .then((response) => {
        if (this.mounted === false) {
          return;
        }

        if (response.status !== 200) {
          this.setState({ isLoadError: true });
          return;
        }

        response.json().then((data) => {
          // console.dir(data);
          this.setState({
            tgusers: data,
            isLoading: false,
          });
        });
      })
      .catch(() => {
        // TODO: Show error to user
      });
  }

  render() {
    const { isLoading, tgusers, isLoadError } = this.state;

    if (isLoadError === true) {
      return (
        <div>
          <b>Ошибка загрузки...</b>
          <br />
          Попробуйте перезагрузить страницу.
        </div>
      );
    }

    if (isLoading) {
      return (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      );
    }

    return (
      <React.Fragment>
        <h3 className={"mt-2"}>Уведомления Telegram</h3>
        <Formik
          initialValues={{ userId: "", name: "" }}
          validationSchema={tguserSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            this.createTguser(values, () => {
              setSubmitting(false);
              resetForm();
            });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <fieldset>
                <Row>
                  <Col>
                    <Form.Group controlId="userId">
                      <Form.Label>ID пользователя</Form.Label>
                      <Form.Control
                        name={"userId"}
                        type={"number"}
                        placeholder={"Введите ID"}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.userId}
                        isInvalid={!!errors.userId}
                      />
                      {errors.userId && touched.userId ? (
                        <Form.Control.Feedback type="invalid">
                          {errors.userId}
                        </Form.Control.Feedback>
                      ) : null}
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group controlId="name">
                      <Form.Label>Имя пользователя</Form.Label>
                      <Form.Control
                        name={"name"}
                        type={"text"}
                        placeholder={"Введите имя"}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        isInvalid={!!errors.name}
                      />
                      {errors.name && touched.name ? (
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
                <Button disabled={isSubmitting} variant="primary" type="submit">
                  Добавить
                </Button>
              </fieldset>
            </Form>
          )}
        </Formik>

        <hr />

        {tgusers && tgusers.length > 0 && (
          <Table striped bordered hover size="sm" responsive="sm">
            <thead>
              <tr>
                <th>Вкл</th>
                <th>Активен</th>
                <th>ID</th>
                <th>Имя</th>
                <th>Дата создания</th>
                <th>Последнее изменение</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {tgusers.map((item) => (
                <tr key={item.id}>
                  <td>{item.enabled === true ? "ДА" : null}</td>
                  <td>{item.active === true ? "ДА" : null}</td>
                  <td>{item.userId}</td>
                  <td>{item.name}</td>
                  <td>{item.createdAt}</td>
                  <td>{item.updatedAt}</td>
                  <td>
                    <div className="btn-group">
                      <Button
                        variant="primary"
                        onClick={() => {
                          if (window.confirm("Вы уверены?"))
                            this.removeTguser(item.id);
                        }}
                      >
                        Удалить
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </React.Fragment>
    );
  }
}
