import React, { Component } from "react";
import { Row, Col, Form, Button, Table } from "react-bootstrap";
// import styled from 'styled-components';
// import Container from 'react-bootstrap/Container';
// import { Redirect } from 'react-router-dom';

class InstalledList extends Component {
  constructor(props) {
    super(props);

    this.mounted = false;

    this.state = {
      priceSelected: 0,
    };

    // this.handleRemove = this.handleRemove.bind(this);
    this.handleChangeCount = this.handleChangeCount.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate(prevProps) {
    const { installType, onChange } = this.props;
    if (prevProps.installType !== installType) {
      onChange([]);
    }
  }

  handleRemove(index) {
    const { onChange } = this.props;
    let { values } = this.props;

    if (!values[parseInt(index, 10)]) {
      return;
    }

    values.splice(parseInt(index, 10), 1);

    onChange(values);
  }

  handleChangeCount(event) {
    const target = event.target;
    let value = parseInt(target.value);
    const index = parseInt(target.dataset.index, 10);

    const { values, onChange } = this.props;

    if (!values[index]) {
      return;
    }

    if (value < 1) {
      this.handleRemove(index);
      return;
    }

    values[index].count = value;
    onChange(values);
  }

  handleChange(event) {
    // console.log(event.target.value);
    this.setState({ priceSelected: parseInt(event.target.value, 10) });
  }

  handleAdd(event) {
    event.preventDefault();

    const { priceSelected } = this.state;
    const { prices, values, onChange, installType = "hand" } = this.props;

    if (
      priceSelected === 0 ||
      !prices[priceSelected - 1] //||
      // prices[priceSelected].install === 0
    ) {
      return;
    }

    const price = prices[priceSelected - 1];

    let isExist = false;
    for (let i = 0; i < values.length; i++) {
      if (values[i].id === `install-${installType}-${price.id}`) {
        isExist = true;
        values[i].count++;
      }
    }

    if (isExist === true) {
      onChange(values);
      return;
    }

    const product = {
      id: `install-${installType}-${price.id}`,
      name: `${price.name}-${price.len}`,
      price: installType === "hand" ? price.install : price.install_mech,
      count: 1,
      type: installType,
    };

    onChange([...this.props.values, product]);

    this.setState({
      priceSelected: 0,
    });
  }

  render() {
    const { priceSelected } = this.state;
    const { values, prices, installType = "hand" } = this.props;

    let sum = 0;
    for (let i = 0; i < values.length; i++) {
      sum += values[i].count * values[i].price;
    }

    const priceGroups = [];
    for (let i = 0; i < prices.length; i++) {
      let index = null;

      for (let j = 0; j < priceGroups.length; j++) {
        if (priceGroups[j].name === prices[i].name) {
          index = j;
        }
      }

      if (index === null) {
        priceGroups.push({ name: prices[i].name, prices: [prices[i]] });
      } else {
        priceGroups[index].prices.push(prices[i]);
      }
    }

    return (
      <React.Fragment>
        <Row>
          <Form.Group as={Col} controlId="formPrices">
            <Form.Control
              name="formPrices"
              as="select"
              value={priceSelected}
              onChange={this.handleChange}
            >
              <option key="0" value="0">
                Выберите...
              </option>

              {priceGroups.map((group, groupIndex) => (
                <optgroup key={`group-${groupIndex}`} label={group.name}>
                  {group.prices.map((price) => (
                    <option
                      key={price.id}
                      value={price.id}
                      data-install={price.install}
                      data-installmech={price.install_mech}
                    >
                      {price.name}-{price.len}&nbsp;
                      {installType === "hand"
                        ? `(руч: ${price.install} руб)`
                        : `(бкм: ${price.install_mech} руб)`}
                    </option>
                  ))}
                </optgroup>
              ))}
            </Form.Control>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} md={2} controlId="formPriceAdd">
            <Button
              name="formPriceAdd"
              type="button"
              variant="primary"
              onClick={this.handleAdd}
            >
              Добавить
            </Button>
          </Form.Group>
        </Row>

        {values && values.length > 0 && (
          <React.Fragment>
            <Table striped bordered hover size="sm" responsive="sm">
              <thead>
                <tr>
                  <th>Название</th>
                  <th>Количество</th>
                  <th>Стоимость</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {values.map((item, index) => (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>
                      <Form.Control
                        type="number"
                        min="0"
                        max="1000"
                        step="1"
                        value={item.count}
                        data-index={index}
                        onChange={this.handleChangeCount}
                      />
                    </td>
                    <td>
                      {new Intl.NumberFormat("ru-RU", {
                        style: "currency",
                        currency: "RUB",
                      }).format(item.price)}
                    </td>
                    <td>
                      <button
                        type="button"
                        onClick={() => {
                          this.handleRemove(index);
                        }}
                      >
                        <i className="oi oi-circle-x" />
                      </button>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td>&nbsp;</td>
                  <td className="text-right">Итого&nbsp;</td>
                  <td>
                    {new Intl.NumberFormat("ru-RU", {
                      style: "currency",
                      currency: "RUB",
                    }).format(sum)}
                  </td>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </Table>

            {/*<h3>*/}
            {/*  Итого:&nbsp;*/}
            {/*  <span>*/}
            {/*    {new Intl.NumberFormat("ru-RU", {*/}
            {/*      style: "currency",*/}
            {/*      currency: "RUB"*/}
            {/*    }).format(sum)}*/}
            {/*  </span>*/}
            {/*</h3>*/}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default InstalledList;
