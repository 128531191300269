import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Alert } from 'react-bootstrap';
import Bus from '../Bus';

const Flash = () => {

  let [visibility, setVisibility] = useState(false);
  let [message, setMessage] = useState('');
  let [type, setType] = useState('');

  useEffect(() => {
    Bus.addListener('flash', ({ message, type }) => {
      // console.log('fhas');
      setVisibility(true);
      setMessage(message);
      setType(type);
      setTimeout(() => {
        setVisibility(false);
      }, 3000);
    });
  }, []);

  // useEffect(() => {
  //     if(document.querySelector('.close') !== null) {
  //         document.
  //         querySelector('.close').
  //         addEventListener('click', () => setVisibility(false));
  //     }
  // })

  const StAlert = styled(Alert)`
        position: absolute;
        top: 10px;
        right: 10px;
    `;

  return (
    visibility &&
    <StAlert className={`alert alert-${type}`} role="alert">
      {/*<span className="close"><strong>X</strong></span>*/}
      {message}
    </StAlert>
  );
};

export default Flash;
