import React, { Component } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import auth from "./auth";

import UpdatePrice from "./Home/UpdatePrice";

import logo from "./logo80.png";

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.mounted = false;

    this.state = {
      authenticated: true,
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { user, handleUpdatePrice } = this.props;
    const { authenticated } = this.state;

    if (authenticated === false) {
      return <Redirect to="/login" />;
    }

    return (
      <Navbar bg="dark" variant="dark" expand="lg">
        {/* fixed="top" */}
        <Navbar.Brand as={Link} to="/">
          <img alt="" src={logo} width="80" height="64" />
          {" Helix-Pro"}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {/* <Nav.Link href="#home">Главная</Nav.Link> */}
            {/* <Nav.Link href="#login">Вход</Nav.Link> */}
            <Nav.Link
              onClick={() => {
                auth.logout(() => {
                  this.setState({
                    authenticated: false,
                  });
                });

                return false;
              }}
            >
              Выход
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse>
          <Navbar.Text className="text-center">
            <UpdatePrice handleUpdatePrice={handleUpdatePrice} />
          </Navbar.Text>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>Пользователь:&nbsp;{user}</Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}
