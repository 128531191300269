import React, { Component } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Redirect } from "react-router-dom";

import Finform from "./Finform";
import Header from "../Header";
import Footer from "../Footer";
import Tgusers from "./Tgusers";
import Users from "./Users";
import Collapsed from "../Collapsed";
import UserEdit from "./Users/UserEdit";

class Home extends Component {
  constructor(props) {
    super(props);

    this.mounted = false;

    this.state = {
      user: null,
      prices: null,
      isLoadError: false,
      isPriceLoading: true,
      isLoading: true,
      authenticated: true,
    };

    this.handleUpdatePrice = this.handleUpdatePrice.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    this.loadUserData();
    this.loadPriceData();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  loadUserData() {
    if (!process.env.REACT_APP_API_URL) {
      throw new Error("API URL not found");
    }

    const apiUrl = process.env.REACT_APP_API_URL || "";
    fetch(`${apiUrl}/user`, {
      credentials: "include",
      cache: "no-cache",
    })
      .then((response) => {
        if (this.mounted === false) {
          return;
        }

        if (response.status === 401 || response.status === 404) {
          // alert(response.status);
          this.setState({ authenticated: false });
          return;
        }

        response.json().then((data) => {
          // console.dir(data);
          this.setState({
            user: data,
            authenticated: true,
            isLoading: false,
          });
        });
      })
      .catch(() => {
        // TODO: Show error to user
      });
  }

  handleUpdatePrice() {
    this.loadPriceData();
  }

  loadPriceData() {
    if (!process.env.REACT_APP_API_URL) {
      throw new Error("API URL not found");
    }

    const apiUrl = process.env.REACT_APP_API_URL || "";
    fetch(`${apiUrl}/prices`, {
      credentials: "include",
      cache: "no-cache",
    })
      .then((response) => {
        if (this.mounted === false) {
          return;
        }

        if (response.status !== 200) {
          // alert(response.status);
          this.setState({
            isLoadError: true,
            isPriceLoading: false,
          });
          return;
        }

        response.json().then((data) => {
          // console.dir(data);
          this.setState({
            prices: data,
            isPriceLoading: false,
          });
        });
      })
      .catch(() => {
        // TODO: Show error to user
      });
  }

  render() {
    const {
      isLoading,
      prices,
      isLoadError,
      isPriceLoading,
      authenticated,
      user,
    } = this.state;

    if (authenticated === false) {
      return <Redirect to="/login" />;
    }

    if (isLoadError === true) {
      return (
        <div>
          <b>Ошибка загрузки товаров.</b>
          <br />
          Попробуйте перезагрузить страницу.
        </div>
      );
    }

    if (isLoading || isPriceLoading) {
      return <div>Loading...</div>;
    }

    const { admin } = user;

    const { match } = this.props;
    const { params } = match;
    const { userID } = params;

    if (userID && userID > 0 && admin === true) {
      return (
        <Container>
          <Header handleUpdatePrice={this.handleUpdatePrice} user={user.name} />
          <UserEdit userID={userID} />
          <br />
          <br />
          <br />
          <Footer />
        </Container>
      );
    }

    return (
      <Container>
        <Header handleUpdatePrice={this.handleUpdatePrice} user={user.name} />
        {admin === true ? (
          <Row className={"my-2"}>
            <Col>
              <Collapsed variant="outline-primary" name={"Бригадиры"}>
                <React.Fragment>
                  <Users />
                  <br />
                  <hr />
                  <br />
                </React.Fragment>
              </Collapsed>
            </Col>
          </Row>
        ) : null}
        {admin === true ? (
          <Row>
            <Col>
              <Collapsed variant="outline-primary" name={"Telegram"}>
                <React.Fragment>
                  <Tgusers />
                  <br />
                  <hr />
                  <br />
                </React.Fragment>
              </Collapsed>
            </Col>
          </Row>
        ) : null}
        <Finform user={user} prices={prices} />
        <br />
        <br />
        <br />
        <Footer />
      </Container>
    );
  }
}

export default styled(Home)``;
