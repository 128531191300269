import React, { Component } from "react";
import { Row, Col, Form, Button, Table } from "react-bootstrap";
// import styled from 'styled-components';
// import Container from 'react-bootstrap/Container';
// import { Redirect } from 'react-router-dom';

class ReturnedList extends Component {
  constructor(props) {
    super(props);

    this.mounted = false;

    // const { values } = props;

    this.state = {
      priceSelected: 0,
    };

    // this.handleRemove = this.handleRemove.bind(this);
    this.handleChangeCount = this.handleChangeCount.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleRemove(index) {
    const { onChange } = this.props;
    let { values } = this.props;

    if (!values[parseInt(index, 10)]) {
      return;
    }

    values.splice(parseInt(index, 10), 1);

    onChange(values);
  }

  handleChangeCount(event) {
    const target = event.target;
    let value = parseInt(target.value);
    const index = parseInt(target.dataset.index, 10);

    const { values, onChange } = this.props;

    if (!values[index]) {
      return;
    }

    if (value < 1) {
      this.handleRemove(index);
      return;
    }

    values[index].count = value;
    onChange(values);
  }

  handleChange(event) {
    // console.log(event.target.value);
    this.setState({ priceSelected: parseInt(event.target.value, 10) });
  }

  handleAdd(event) {
    event.preventDefault();

    const { priceSelected } = this.state;
    const { prices, values, onChange } = this.props;

    if (priceSelected === 0 || !prices[priceSelected - 1]) {
      return;
    }

    const price = prices[priceSelected - 1];

    let isExist = false;
    for (let i = 0; i < values.length; i++) {
      if (values[i].id === `return-${price.id}`) {
        isExist = true;
        values[i].count++;
      }
    }

    if (isExist === true) {
      onChange(values);
      return;
    }

    const product = {
      id: `return-${price.id}`,
      name: `${price.name}-${price.len}`,
      count: 1,
    };

    onChange([...this.props.values, product]);

    this.setState({
      priceSelected: 0,
    });
  }

  render() {
    const { priceSelected } = this.state;
    const { values, prices } = this.props;

    const priceGroups = [];
    for (let i = 0; i < prices.length; i++) {
      let index = null;

      for (let j = 0; j < priceGroups.length; j++) {
        if (priceGroups[j].name === prices[i].name) {
          index = j;
        }
      }

      if (index === null) {
        priceGroups.push({ name: prices[i].name, prices: [prices[i]] });
      } else {
        priceGroups[index].prices.push(prices[i]);
      }
    }

    return (
      <fieldset>
        <Row>
          <Form.Group as={Col} controlId="formPricesReturn">
            <Form.Control
              name="formPricesReturn"
              as="select"
              value={priceSelected}
              onChange={this.handleChange}
            >
              <option key="0" value="0">
                Выберите...
              </option>

              {priceGroups.map((group, groupIndex) => (
                <optgroup key={`group-${groupIndex}`} label={group.name}>
                  {group.prices.map((price) => (
                    <option key={price.id} value={price.id}>
                      {price.name}-{price.len}
                    </option>
                  ))}
                </optgroup>
              ))}
            </Form.Control>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} md={2} controlId="formPriceAddReturn">
            <Button
              name="formPriceAddReturn"
              type="button"
              variant="primary"
              onClick={this.handleAdd}
            >
              Добавить
            </Button>
          </Form.Group>
        </Row>

        {values && values.length > 0 && (
          <Table striped bordered hover size="sm" responsive="sm">
            <thead>
              <tr>
                <th>Название</th>
                <th>Количество</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {values.map((item, index) => (
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>
                    <Form.Control
                      type="number"
                      min="0"
                      max="1000"
                      step="1"
                      value={item.count}
                      data-index={index}
                      onChange={this.handleChangeCount}
                    />
                  </td>
                  <td>
                    <button
                      type="button"
                      onClick={() => {
                        this.handleRemove(index);
                      }}
                    >
                      <i className="oi oi-circle-x" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </fieldset>
    );
  }
}

export default ReturnedList;
